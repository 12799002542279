
import { AnyObject } from 'node_modules/element-plus/lib/el-table/src/table.type';
import { AnyFunction } from 'node_modules/element-plus/lib/utils/types';
import { defineComponent, reactive, inject, ref } from 'vue';

export default defineComponent({
  setup(props, context) {
    const idAllow = (rule: AnyObject, value: number, callback: AnyFunction<null>) => {
      if (Number(value) > 2147483647) {
        callback(new Error('输入ID无效'));
      } else {
        callback();
      }
    };
    const fromRef = ref();
    const data = reactive({
      searchInfo: {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: inject('verifyStatus'), // 审核状态
      },
      verifyStateOptions: inject('selectOptions'),
      showAll: true, // 是否展开或者折叠
      rules: {
        postId: [
          { validator: idAllow, trigger: 'change' },
        ],
        commentId: [
          { validator: idAllow, trigger: 'change' },
        ],
      },
    });
    const handleSearch = () => {
      fromRef.value.validate((valid: boolean) => {
        if (valid) {
          context.emit('getInfo', data.searchInfo);
        } else {
          return false;
        }
      });
    };
    const mastNumber = (key: 'postId' | 'createQQ' | 'commentId') => {
      Object.keys(data.searchInfo).forEach((k) => {
        if (key === k) {
          data.searchInfo[key] = (data.searchInfo[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const defverifyState = inject('verifyStatus');
    const clearSearchInfoFn = () => {
      data.searchInfo = {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: defverifyState, // 审核状态
      };
      context.emit('getInfo', data.searchInfo);
    };
    const changeSerchStype = () => {
      data.showAll = !data.showAll;
      context.emit('changeTableHight', data.showAll);
    };
    return {
      data,
      // handleSearch,
      handleSearch,
      changeSerchStype,
      clearSearchInfoFn,
      mastNumber,
      fromRef,
    };
  },
});
