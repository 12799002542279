<template>
  <div class="replySearch">
    <el-form :model="data.searchInfo" :rules="data.rules" ref="fromRef">
      <div :class="data.showAll ? 'replySearch-all heighter' : 'replySearch-all middler' ">
        <el-row :gutter="65" class="serarchContainer">
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">回帖ID</p>
              <el-form-item prop="postId">
                <el-input v-model="data.searchInfo.postId"
                  @input="mastNumber('postId')"
                  placeholder="请输入"></el-input>
               </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">发表者昵称</p>
              <el-input v-model="data.searchInfo.createNickName"
              placeholder="请输入"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">发表者QQ</p>
              <el-input v-model="data.searchInfo.createQQ"
              @input="mastNumber('createQQ')"
              placeholder="请输入"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt"  v-if="data.showAll">
              <p class="searchLable">发表者微信</p>
              <el-input
              v-model="data.searchInfo.createWx"
              placeholder="请输入"></el-input>
            </div>
            <div class="searchInpt haveBtn" v-else>
              <div class="searchBtn">
                <el-button type="primary" size="medium" @click="handleSearch">检索</el-button>
                <el-button size="medium" @click="clearSearchInfoFn" class="resetBtn">重置</el-button>
                <el-button type="text" @click="changeSerchStype">
                  展开<i class="showBtn el-icon-arrow-down el-icon--right"></i>
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="65" v-if="data.showAll">
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">所属社区</p>
              <el-input
              v-model="data.searchInfo.communityName"
              placeholder="请输入"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">回复ID</p>
              <el-form-item prop="commentId">
                <el-input
                v-model="data.searchInfo.commentId"
                @input="mastNumber('commentId')"
                placeholder="请输入"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">发表时间</p>
              <!-- <el-input v-model="region"></el-input> -->
              <el-date-picker
                class="search-date-picker"
                v-model="data.searchInfo.sendDate"
                :default-time="[
                  new Date(2000, 1, 1, 0, 0, 0),
                  new Date(2000, 2, 1, 23, 59, 59)
                ]"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchInpt">
              <p class="searchLable">审核状态</p>
              <el-select v-model="data.searchInfo.verifyStatus" placeholder="请选择">
                <el-option
                  v-for="item in data.verifyStateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <div class="searchBtn">
                <el-button type="primary" size="medium" @click="handleSearch">检索</el-button>
                <el-button size="medium" @click="clearSearchInfoFn" class="resetBtn">重置</el-button>
                <el-button type="text" @click="changeSerchStype">
                  收起<i class="showBtn el-icon-arrow-up el-icon--right"></i>
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import { AnyObject } from 'node_modules/element-plus/lib/el-table/src/table.type';
import { AnyFunction } from 'node_modules/element-plus/lib/utils/types';
import { defineComponent, reactive, inject, ref } from 'vue';

export default defineComponent({
  setup(props, context) {
    const idAllow = (rule: AnyObject, value: number, callback: AnyFunction<null>) => {
      if (Number(value) > 2147483647) {
        callback(new Error('输入ID无效'));
      } else {
        callback();
      }
    };
    const fromRef = ref();
    const data = reactive({
      searchInfo: {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: inject('verifyStatus'), // 审核状态
      },
      verifyStateOptions: inject('selectOptions'),
      showAll: true, // 是否展开或者折叠
      rules: {
        postId: [
          { validator: idAllow, trigger: 'change' },
        ],
        commentId: [
          { validator: idAllow, trigger: 'change' },
        ],
      },
    });
    const handleSearch = () => {
      fromRef.value.validate((valid: boolean) => {
        if (valid) {
          context.emit('getInfo', data.searchInfo);
        } else {
          return false;
        }
      });
    };
    const mastNumber = (key: 'postId' | 'createQQ' | 'commentId') => {
      Object.keys(data.searchInfo).forEach((k) => {
        if (key === k) {
          data.searchInfo[key] = (data.searchInfo[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const defverifyState = inject('verifyStatus');
    const clearSearchInfoFn = () => {
      data.searchInfo = {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: defverifyState, // 审核状态
      };
      context.emit('getInfo', data.searchInfo);
    };
    const changeSerchStype = () => {
      data.showAll = !data.showAll;
      context.emit('changeTableHight', data.showAll);
    };
    return {
      data,
      // handleSearch,
      handleSearch,
      changeSerchStype,
      clearSearchInfoFn,
      mastNumber,
      fromRef,
    };
  },
});
</script>
<style lang="scss" scoped>
.replySearch{
  padding: 0 32px;
}
  .heighter{
  transition: all 0.1s;
  height: 220px;
}
.noAllowId{
  color: red;
}
.middler{
  transition: all 0.1s;
  height: 88px;
}
.replySearch-all,  .replySearch-small{
  font-size: 14px;
  // padding: 32px;
  padding-bottom: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
  .searchLable{
    margin-bottom: 5px;
    color: #303133;
    // margin-left: calc(100% - 260px);
  }
  .search-date-picker{
    width: 100%;
  }
  .searchInpt{
    .el-form-item{
      margin-bottom: 0;
    }
    // width: 260px;
    min-width: 260px;
    // max-width: 260px;
    // margin: 0 auto;
    :deep() .el-input__inner{
      height: 36px;
      line-height: 36px;
    }
    :deep() .search-date-picker{
      width: 100%;
      padding: 3px 5px;
    }
    :deep() .el-select{
      width: 100%;
    }
  }
  .searchInpBtn{
    width: 100%;
  }
   .searchInpt.haveBtn{
     width: unset;
   }
  .searchInpt::after{
    display: block;
    content: "";
    height: 0;
    overflow: hidden;
    clear: both;
  }
  .searchBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    height: 40px;
    min-width: 250px;
    .resetBtn{
      color: #303133;
    }
    .resetBtn:hover{
      color: #409EFF;
    }
    .el-button+.el-button {
      margin-left: 16px;
    }
    .el-button{
      width: 90px;
      height: 36px
    }
    .el-button:last-of-type{
      width: 50px;
    }
    .el-button--text{
      color: #C0C4CC !important;
    }
  }
  .serarchContainer{
    margin-bottom: 24px;
  }
  .el-icon--right{
    margin-left: 0;
  }
}
</style>
