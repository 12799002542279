
import { defineComponent, inject } from 'vue';
export default defineComponent({
  setup() {
    const faData = inject('senderInfoDailogVisible');
    return {
      faData,
    };
  },
});
