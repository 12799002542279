<template>
  <div class="senderInfoDailog-dialog">
    <el-dialog title="用户信息" top="30vh" center width="400px" v-model="faData.senderInfoDailogVisible">
      <div>
        <el-form label-width="80px">
          <el-form-item label="昵称">
            <span class="renderInfo-text">{{faData.senderInfo.createNickName}}</span>
          </el-form-item>
          <el-form-item label="QQ">
            <span class="renderInfo-text">{{faData.senderInfo.createQQ || '/'}}</span>
          </el-form-item>
          <el-form-item label="微信">
            <span class="renderInfo-text">{{faData.senderInfo.createWx || '/'}}</span>
          </el-form-item>
          <el-form-item label="用户头像">
            <img
              class="renderInfo-text renderInfo-img"
              :src="faData.senderInfo.createIcon || faData.senderInfo.icon"
              alt="">
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="senderdialog-footer">
          <el-button type="primary" @click="faData.senderInfoDailogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from 'vue';
export default defineComponent({
  setup() {
    const faData = inject('senderInfoDailogVisible');
    return {
      faData,
    };
  },
});
</script>
<style lang="scss" scoped>
.senderInfoDailog-dialog{
  :deep() .el-button--primary{
    min-height: 32px;
    padding: 0;
    width: 89px;
  }
  :deep() .el-form-item{
    margin-bottom: 0;
  }
  :deep() .el-dialog__body{
    padding: 0 32px 0px;
  }
  .renderInfo-text{
    display: inline-block;
    margin-left: 20px;
    color: #909399;
  }
  .renderInfo-img{
    padding-top: 14px;
    height: 90px;
    width: 90px;
  }
}
</style>
