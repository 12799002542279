import { AnyObject } from 'element-plus/lib/el-table/src/table.type';

export const numberFormat = function (value: number): AnyObject {
  const param: AnyObject = {};
  const k = 10000;
  if (value < k) {
    param.value = value;
    param.unit = '';
  } else {
    const sizes = ['', '万', '亿', '万亿'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    console.log(i);
    param.value = ((value / (k ** i))).toFixed(2);
    param.unit = sizes[i];
  }
  return param;
};

export const numberThousand = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 千进位

export const replyMessage = (text: string): string => {
  let newText = '';
  if (text.length > 20) {
    newText = `${text.slice(0, 20)}...`;
  } else {
    newText = text;
  }
  return newText;
};
